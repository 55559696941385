@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', cursive;
}

.slider {
  width: 300px;
  text-align: center;
  overflow: hidden;
}

.slides {
  display: flex;
  
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  
  
  
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  
  /*
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
  */
}
.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slides::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}
.slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 300px;
  height: 300px;
  margin-right: 50px;
  border-radius: 10px;
  background: #eee;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  position: relative;
  
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}
.slides > div:target {
   -webkit-transform: scale(0.8);
           transform: scale(0.8); 
}

.slider > a {
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0.5rem 0;
  position: relative;
}
.slider > a:active {
  top: 1px;
}
.slider > a:focus {
  background: #000;
}


/*App*/
.App{
  position: relative;
  /*min-height: 100vh;*/
  background-color: black;
  scroll-behavior: smooth;
}

.app-content{
  min-height: calc(100vh - 57px);
  /*padding-top: 120px;*/
}

/*Home page*/
.home-page{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}





/* Don't need button navigation */
/*@supports (scroll-snap-type) {*/
/*  .slider > a {*/
/*    display: none;*/
/*  }*/
/*}*/

.navbar{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  padding-left: 20px;
  padding-right: 20px;
}

.navbar-logos{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    /*width: 100%;*/
    /*height: 100%;*/
}

.navbar-logo{
  width: 100px;
  height: auto;
  margin-right: 20px;
}

.navbar-logo-two{
  width: 152px;
  height: auto;
  margin-top: 27px;
}

.navbar-account-button{
  /*cursor: pointer;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px;
  /*border: 1px solid transparent;*/
}

/*.navbar-account-button:hover{*/
/*  border-color: #e4e4e4;*/
/*  border-radius: 5px;*/
/*}*/


.navbar-account-circle{
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-account-circle > p{
  color: #050505;
  font-size: 18px;
  font-weight: 600;
}

.navbar-account-text{
  margin-left: 15px;
  color: white;
}

@media only screen and (max-width: 550px) {
  .navbar-logos{
    flex-direction: column;
  }

  .navbar-logo{
    width: 100px;
    height: auto;
    margin-right: 71px;
  }

  .navbar-logo-two{
    width: 130px;
    height: auto;
    margin-right: 41px;
    margin-top: 3px;
  }
}

.choose-activity-section{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}

.choose-activity-section-header{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    padding-left: 100px;
    padding-right: 100px;
}

.choose-activity-section-header > h1{
    color: #FAFBFB;
    font-size: 50px;
}

.choose-activity-section-header > h5{
    color: #FAFBFB;
    font-size: 24px;
    margin-top: 20px;
    margin-left: 6px;
}

.choose-activity-cards-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    padding-left: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.choose-activity-cards-container::-webkit-scrollbar{
    background-color: transparent;
    height: 5px;
}

.choose-activity-cards-container::-webkit-scrollbar-track{
    background: transparent;
}

.choose-activity-cards-container::-webkit-scrollbar-thumb{
    background-color: grey;
    border-radius: 10px;
    height: 10px;
}


@media only screen and (max-width: 800px) {
    .choose-activity-section-header{
        padding-left: 25px;
        padding-right: 25px;
    }
    .choose-activity-cards-container{
        padding-left: 25px;
    }

    .choose-activity-section-header > h1{
        font-size: 40px;
    }

    .choose-activity-section-header > h5{
        font-size: 20px;
        margin-left: 3px;
    }
}

@media only screen and (max-width: 600px) {
    .choose-activity-section-header > h1{
        font-size: 30px;
    }
}

@media only screen and (max-width: 450px) {
    .choose-activity-section-header > h1{
        font-size: 25px;
    }
    .choose-activity-section-header > h5{
        font-size: 16px;
    }
}


@media only screen and (max-width: 400px) {
    .choose-activity-section-header{
        padding-left: 10px;
        padding-right: 10px;
    }
    .choose-activity-cards-container{
        padding-left: 10px;
    }
}






.choose-activity-card{
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    height: 306px;
    margin-right: 45px;
    border-radius: 5px;
    /*margin-bottom: 20px;*/
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.choose-activity-card-overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(72,72,72,0.6);
}

.choose-activity-card-image-container{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}
.choose-activity-card-image-container > img{
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}


.choose-activity-card-text-container{
    background-color: white;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.choose-activity-card-text-container > p{
    color: #1A173F;
    font-size: 20px;
}
.homepage-header{
 position: relative;
 display: flex;
 flex-direction: column;
 flex-wrap: nowrap;
 justify-content: flex-start;
 align-items: stretch;
 padding-left: 100px;
 padding-right: 100px;
 /*height: 800px;*/
 padding-top: 120px;
 background-repeat: no-repeat;
 background-position: 99% 0;
 background-size: 110%;
 padding-bottom: 30px;
}

.homepage-header-overlay{
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
    background-color: rgba(0,0,0,0.03);
}

.welcome-header-text{
 color: white;
 font-size: 50px;
 text-shadow: 2px 0px 5px black;
 margin-bottom: 20px;
 z-index: 1;
}

.connect-to-immerse-text{
 color: white;
 margin-top: 5px;
 padding-left: 4px;
 text-shadow: 2px 0px 5px black;
 z-index: 1;

}


/* connect metamask section */
.connect-metamask-section{
 position: relative;
 background-color: #29134ECC;
 display: flex;
 flex-direction: row;
 flex-wrap: nowrap;
 justify-content: flex-start;
 align-items: stretch;
 box-shadow: 0px 3px 6px #00000029;
 border: 0.5px solid #FFFFFF1A;
 border-radius: 5px;
 width: 430px;
 padding: 10px;
 margin-top: 20px;
 margin-bottom: 20px;
 height: 140px;
 z-index: 1;

}

.metamask-address-text{
 position: absolute;
 bottom: 8px;
 left: 0;
 width: 100%;
 text-align: center;
 color: #FAFBFB;
 font-size: 12px;
 z-index: 1;

}

.connect-metamask-section > div:nth-child(1){
 display: flex;
 justify-content: center;
 align-items: center;
}

.connect-metamask-section > div:nth-child(1) > img{
 width: 87px;
 height: 81px;
}

.connect-metamask-section > div:nth-child(2){
 display: flex;
 flex-direction: column;
 flex-wrap: nowrap;
 justify-content: center;
 align-items: stretch;
 padding-left: 15px;
 padding-right: 10px;
 z-index: -1;

}

.connect-metamask-section > div:nth-child(2) > button{
 cursor: pointer;
 color: black;
 background-color: gray;
 font-size: 12px;
 height: 40px;
 box-shadow: 0px 3px 6px #00000029;
 margin-bottom: 10px;
 border: none;
 border-radius: 5px;
 z-index: -10;
}

.connect-metamask-section > div:nth-child(2) > button:hover{
    background-color: rgba(0,0,0,1);
    color: gray;
    z-index: -10;
}

.connect-metamask-section > div:nth-child(2) > p{
 color: #FAFBFB;
 font-size: 12px;
 padding-bottom: 2px;
 padding-top: 2px;
 text-align: left;
 margin-bottom: 10px;
}

.connect-metamask-section > div:nth-child(2) > p > a{
 color: #00A0DD;
}

.disabled{
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}



/*Access conference materials section */
.access-conference-material-section{
 background-color: #29134ECC;
 display: flex;
 flex-direction: row;
 flex-wrap: nowrap;
 justify-content: flex-start;
 align-items: stretch;
 box-shadow: 0px 3px 6px #00000029;
 border: 0.5px solid #FFFFFF1A;
 border-radius: 5px;
 width: 430px;
 padding: 10px;
 margin-top: 20px;
 height: 140px;
 z-index: 1;
}

.access-conference-material-section > div:nth-child(1){
 display: flex;
 justify-content: center;
 align-items: center;
}

.access-conference-material-section > div:nth-child(1) > img{
 width: 93px;
 height: 94px;
}


.access-conference-material-section > div:nth-child(2){
 display: flex;
 flex-direction: column;
 flex-wrap: nowrap;
 justify-content: center;
 align-items: stretch;
 padding-left: 10px;
}

.access-conference-material-section > div:nth-child(2) > p{
 color: #FAFBFB;
 font-size: 12px;
 padding-bottom: 3px;
 padding-top: 3px;
}


.access-conference-material-section > div:nth-child(2) > p > a{
 color: #00A0DD;
}




/* Resize based on screen size */

@media only screen and (max-width: 1000px){
  .homepage-header{
   height: unset;
   padding-bottom: 120px;
   background-size: cover;
  }
}

@media only screen and (max-width: 800px){
 .homepage-header{
  height: unset;
  padding-bottom: 120px;
  background-size: cover;
  padding-left: 25px;
  padding-right: 25px;
 }

 .welcome-header-text{
  font-size: 40px;
 }
}


@media only screen and (max-width: 700px){
 .homepage-header {
  padding-top: 200px;
 }
}


/* start mobile device scale*/
@media only screen and (max-width: 600px){
    .homepage-header{
     background-position: 100% 0;
    }

    .welcome-header-text{
     font-size: 30px;
    }


 .access-conference-material-section{
  width: 100%
 }
 .connect-metamask-section{
  width: 100%;
 }
}

@media only screen and (max-width: 500px){
 .connect-metamask-section{
  height: 170px;
 }

 .access-conference-material-section{
  height: 170px;
 }
}



@media only screen and (max-width: 400px){
 .homepage-header{
  padding-left: 10px;
  padding-right: 10px;
 }
}

/*Product page*/
.product-page{
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
}

.product-page-inner{
    width: 80%
}

.product-page-header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 20px;
}

.product-page-header > div:nth-child(1){
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.product-page-header > div:nth-child(1) > img{
    background: url(/static/media/NeonFrame.c22633b2.png);
    height: 200px;
    width: auto;
    background-size: contain;
    margin-right: 11px;
    background-repeat: no-repeat;
    padding: 15px;
}

.product-page-header > div:nth-child(2){
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    text-align: left;
    color: white;
    padding-left: 40px;
}
.product-page-header > div:nth-child(2) > h1{
    margin-top: 10px;
    margin-bottom: 10px;
}

.product-page-header > div:nth-child(2) > p{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
}

.product-form{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    /*align-items: stretch;*/
    align-items: center;
}



/*Buttons*/
.product-page-buttons-container{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
}

.product-page-buttons-container > button{
    width: 140px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #00A0DD;
    color: #FAFBFB;
    font-size: 16px;
    cursor: pointer;
}

.submit-button{
    background-color: #00A0DD;
}

.cancel-button{
    font-size: 16px;
    background-color: transparent;
}



/*Custom input*/

.error-input-alert{
    background-color: #AE172B;
    /*height: 59px;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
    margin-top: 10px;
    margin-bottom: 20px;
}

.error-input-alert > p{
    color: white;
    font-size: 15px;
    padding: 15px;
}

.custom-input-label{
    color: white;
    margin-top: 10px;
    margin-left: 5px;
    margin-bottom: 5px;
    width: 80%;
}

.custom-input{
    border-radius: 5px;
    border: 1px solid #FFFFFF33;
    color: #FAFBFB;
    height: 40px;
    background-color: rgba(0,0,0,0.5);
    padding-left: 20px;
    margin-bottom: 10px;
    width: 80%;
}

.custom-input-error{
    border-color: #E97886 !important;
    border-width: 2px !important;
    background-color: rgba(235,118,137,0.10196078431372549) !important;
}

@media only screen and (max-width: 700px){
    .product-page-header{
        padding-left: 20px;
        padding-right: 20px;
    }

    .product-page-inner{
        width: 100%;
    }

    .product-page-buttons-container{
        width: 100%;
    }

}

/*.checkbox-container{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    flex-wrap: nowrap;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.checkbox-container > p{*/
/*    margin-left: 10px;*/
/*    color: white;*/
/*    */
/*}*/
.footer{
    background-color: black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 57px
}

.footer-text{
    color: white;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
}

.footer-link{
    color: white;
    text-decoration: underline;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
}

.no-nft-modal-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
}

.accept-terms-modal-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
}


.modal-container{
    /*position: absolute;*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    background-image: url(/static/media/confetti.7c62a174.gif);
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
}

.modal{
    width: 80%;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    padding-bottom: 60px;
    margin-top: 50px;
    text-align: center;
}

.modal-close-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.modal-close-container > p{
    cursor: pointer;
}

.p-margin-bottom {
    margin-bottom: 15px;
}

.back-to-home-button{
    width: 140px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #00A0DD;
    color: #FAFBFB;
    font-size: 16px;
    cursor: pointer;
    background-color: #00A0DD;
    margin-top: 20px;
}

.red-close-button{
    width: 140px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ef404c;
    color: #FAFBFB;
    font-size: 16px;
    cursor: pointer;
    background-color: #ef404c;
    margin-top: 20px;
}

.green-agree-button{
    width: 140px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #00984A;
    color: #FAFBFB;
    font-size: 16px;
    cursor: pointer;
    background-color: #00984A;
    margin-top: 20px;
}

