.homepage-header{
 position: relative;
 display: flex;
 flex-direction: column;
 flex-wrap: nowrap;
 justify-content: flex-start;
 align-items: stretch;
 padding-left: 100px;
 padding-right: 100px;
 /*height: 800px;*/
 padding-top: 120px;
 background-repeat: no-repeat;
 background-position: 99% 0;
 background-size: 110%;
 padding-bottom: 30px;
}

.homepage-header-overlay{
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
    background-color: rgba(0,0,0,0.03);
}

.welcome-header-text{
 color: white;
 font-size: 50px;
 text-shadow: 2px 0px 5px black;
 margin-bottom: 20px;
 z-index: 1;
}

.connect-to-immerse-text{
 color: white;
 margin-top: 5px;
 padding-left: 4px;
 text-shadow: 2px 0px 5px black;
 z-index: 1;

}


/* connect metamask section */
.connect-metamask-section{
 position: relative;
 background-color: #29134ECC;
 display: flex;
 flex-direction: row;
 flex-wrap: nowrap;
 justify-content: flex-start;
 align-items: stretch;
 box-shadow: 0px 3px 6px #00000029;
 border: 0.5px solid #FFFFFF1A;
 border-radius: 5px;
 width: 430px;
 padding: 10px;
 margin-top: 20px;
 margin-bottom: 20px;
 height: 140px;
 z-index: 1;

}

.metamask-address-text{
 position: absolute;
 bottom: 8px;
 left: 0;
 width: 100%;
 text-align: center;
 color: #FAFBFB;
 font-size: 12px;
 z-index: 1;

}

.connect-metamask-section > div:nth-child(1){
 display: flex;
 justify-content: center;
 align-items: center;
}

.connect-metamask-section > div:nth-child(1) > img{
 width: 87px;
 height: 81px;
}

.connect-metamask-section > div:nth-child(2){
 display: flex;
 flex-direction: column;
 flex-wrap: nowrap;
 justify-content: center;
 align-items: stretch;
 padding-left: 15px;
 padding-right: 10px;
 z-index: -1;

}

.connect-metamask-section > div:nth-child(2) > button{
 cursor: pointer;
 color: black;
 background-color: gray;
 font-size: 12px;
 height: 40px;
 box-shadow: 0px 3px 6px #00000029;
 margin-bottom: 10px;
 border: none;
 border-radius: 5px;
 z-index: -10;
}

.connect-metamask-section > div:nth-child(2) > button:hover{
    background-color: rgba(0,0,0,1);
    color: gray;
    z-index: -10;
}

.connect-metamask-section > div:nth-child(2) > p{
 color: #FAFBFB;
 font-size: 12px;
 padding-bottom: 2px;
 padding-top: 2px;
 text-align: left;
 margin-bottom: 10px;
}

.connect-metamask-section > div:nth-child(2) > p > a{
 color: #00A0DD;
}

.disabled{
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}



/*Access conference materials section */
.access-conference-material-section{
 background-color: #29134ECC;
 display: flex;
 flex-direction: row;
 flex-wrap: nowrap;
 justify-content: flex-start;
 align-items: stretch;
 box-shadow: 0px 3px 6px #00000029;
 border: 0.5px solid #FFFFFF1A;
 border-radius: 5px;
 width: 430px;
 padding: 10px;
 margin-top: 20px;
 height: 140px;
 z-index: 1;
}

.access-conference-material-section > div:nth-child(1){
 display: flex;
 justify-content: center;
 align-items: center;
}

.access-conference-material-section > div:nth-child(1) > img{
 width: 93px;
 height: 94px;
}


.access-conference-material-section > div:nth-child(2){
 display: flex;
 flex-direction: column;
 flex-wrap: nowrap;
 justify-content: center;
 align-items: stretch;
 padding-left: 10px;
}

.access-conference-material-section > div:nth-child(2) > p{
 color: #FAFBFB;
 font-size: 12px;
 padding-bottom: 3px;
 padding-top: 3px;
}


.access-conference-material-section > div:nth-child(2) > p > a{
 color: #00A0DD;
}




/* Resize based on screen size */

@media only screen and (max-width: 1000px){
  .homepage-header{
   height: unset;
   padding-bottom: 120px;
   background-size: cover;
  }
}

@media only screen and (max-width: 800px){
 .homepage-header{
  height: unset;
  padding-bottom: 120px;
  background-size: cover;
  padding-left: 25px;
  padding-right: 25px;
 }

 .welcome-header-text{
  font-size: 40px;
 }
}


@media only screen and (max-width: 700px){
 .homepage-header {
  padding-top: 200px;
 }
}


/* start mobile device scale*/
@media only screen and (max-width: 600px){
    .homepage-header{
     background-position: 100% 0;
    }

    .welcome-header-text{
     font-size: 30px;
    }


 .access-conference-material-section{
  width: 100%
 }
 .connect-metamask-section{
  width: 100%;
 }
}

@media only screen and (max-width: 500px){
 .connect-metamask-section{
  height: 170px;
 }

 .access-conference-material-section{
  height: 170px;
 }
}



@media only screen and (max-width: 400px){
 .homepage-header{
  padding-left: 10px;
  padding-right: 10px;
 }
}
