.navbar{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  padding-left: 20px;
  padding-right: 20px;
}

.navbar-logos{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    /*width: 100%;*/
    /*height: 100%;*/
}

.navbar-logo{
  width: 100px;
  height: auto;
  margin-right: 20px;
}

.navbar-logo-two{
  width: 152px;
  height: auto;
  margin-top: 27px;
}

.navbar-account-button{
  /*cursor: pointer;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px;
  /*border: 1px solid transparent;*/
}

/*.navbar-account-button:hover{*/
/*  border-color: #e4e4e4;*/
/*  border-radius: 5px;*/
/*}*/


.navbar-account-circle{
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-account-circle > p{
  color: #050505;
  font-size: 18px;
  font-weight: 600;
}

.navbar-account-text{
  margin-left: 15px;
  color: white;
}

@media only screen and (max-width: 550px) {
  .navbar-logos{
    flex-direction: column;
  }

  .navbar-logo{
    width: 100px;
    height: auto;
    margin-right: 71px;
  }

  .navbar-logo-two{
    width: 130px;
    height: auto;
    margin-right: 41px;
    margin-top: 3px;
  }
}