
.no-nft-modal-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
}

.accept-terms-modal-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
}


.modal-container{
    /*position: absolute;*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    background-image: url('../../images/confetti.gif');
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
}

.modal{
    width: 80%;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    padding-bottom: 60px;
    margin-top: 50px;
    text-align: center;
}

.modal-close-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.modal-close-container > p{
    cursor: pointer;
}

.p-margin-bottom {
    margin-bottom: 15px;
}

.back-to-home-button{
    width: 140px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #00A0DD;
    color: #FAFBFB;
    font-size: 16px;
    cursor: pointer;
    background-color: #00A0DD;
    margin-top: 20px;
}

.red-close-button{
    width: 140px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ef404c;
    color: #FAFBFB;
    font-size: 16px;
    cursor: pointer;
    background-color: #ef404c;
    margin-top: 20px;
}

.green-agree-button{
    width: 140px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #00984A;
    color: #FAFBFB;
    font-size: 16px;
    cursor: pointer;
    background-color: #00984A;
    margin-top: 20px;
}
