
.choose-activity-section{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}

.choose-activity-section-header{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    padding-left: 100px;
    padding-right: 100px;
}

.choose-activity-section-header > h1{
    color: #FAFBFB;
    font-size: 50px;
}

.choose-activity-section-header > h5{
    color: #FAFBFB;
    font-size: 24px;
    margin-top: 20px;
    margin-left: 6px;
}

.choose-activity-cards-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    padding-left: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.choose-activity-cards-container::-webkit-scrollbar{
    background-color: transparent;
    height: 5px;
}

.choose-activity-cards-container::-webkit-scrollbar-track{
    background: transparent;
}

.choose-activity-cards-container::-webkit-scrollbar-thumb{
    background-color: grey;
    border-radius: 10px;
    height: 10px;
}


@media only screen and (max-width: 800px) {
    .choose-activity-section-header{
        padding-left: 25px;
        padding-right: 25px;
    }
    .choose-activity-cards-container{
        padding-left: 25px;
    }

    .choose-activity-section-header > h1{
        font-size: 40px;
    }

    .choose-activity-section-header > h5{
        font-size: 20px;
        margin-left: 3px;
    }
}

@media only screen and (max-width: 600px) {
    .choose-activity-section-header > h1{
        font-size: 30px;
    }
}

@media only screen and (max-width: 450px) {
    .choose-activity-section-header > h1{
        font-size: 25px;
    }
    .choose-activity-section-header > h5{
        font-size: 16px;
    }
}


@media only screen and (max-width: 400px) {
    .choose-activity-section-header{
        padding-left: 10px;
        padding-right: 10px;
    }
    .choose-activity-cards-container{
        padding-left: 10px;
    }
}





