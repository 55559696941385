.choose-activity-card{
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    height: 306px;
    margin-right: 45px;
    border-radius: 5px;
    /*margin-bottom: 20px;*/
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.choose-activity-card-overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(72,72,72,0.6);
}

.choose-activity-card-image-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}
.choose-activity-card-image-container > img{
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}


.choose-activity-card-text-container{
    background-color: white;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.choose-activity-card-text-container > p{
    color: #1A173F;
    font-size: 20px;
}