/*Product page*/
.product-page{
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
}

.product-page-inner{
    width: 80%
}

.product-page-header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 20px;
}

.product-page-header > div:nth-child(1){
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.product-page-header > div:nth-child(1) > img{
    background: url("../../images/NeonFrame.png");
    height: 200px;
    width: auto;
    background-size: contain;
    margin-right: 11px;
    background-repeat: no-repeat;
    padding: 15px;
}

.product-page-header > div:nth-child(2){
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    text-align: left;
    color: white;
    padding-left: 40px;
}
.product-page-header > div:nth-child(2) > h1{
    margin-top: 10px;
    margin-bottom: 10px;
}

.product-page-header > div:nth-child(2) > p{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
}

.product-form{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    /*align-items: stretch;*/
    align-items: center;
}



/*Buttons*/
.product-page-buttons-container{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
}

.product-page-buttons-container > button{
    width: 140px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #00A0DD;
    color: #FAFBFB;
    font-size: 16px;
    cursor: pointer;
}

.submit-button{
    background-color: #00A0DD;
}

.cancel-button{
    font-size: 16px;
    background-color: transparent;
}



/*Custom input*/

.error-input-alert{
    background-color: #AE172B;
    /*height: 59px;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
    margin-top: 10px;
    margin-bottom: 20px;
}

.error-input-alert > p{
    color: white;
    font-size: 15px;
    padding: 15px;
}

.custom-input-label{
    color: white;
    margin-top: 10px;
    margin-left: 5px;
    margin-bottom: 5px;
    width: 80%;
}

.custom-input{
    border-radius: 5px;
    border: 1px solid #FFFFFF33;
    color: #FAFBFB;
    height: 40px;
    background-color: rgba(0,0,0,0.5);
    padding-left: 20px;
    margin-bottom: 10px;
    width: 80%;
}

.custom-input-error{
    border-color: #E97886 !important;
    border-width: 2px !important;
    background-color: rgba(235,118,137,0.10196078431372549) !important;
}

@media only screen and (max-width: 700px){
    .product-page-header{
        padding-left: 20px;
        padding-right: 20px;
    }

    .product-page-inner{
        width: 100%;
    }

    .product-page-buttons-container{
        width: 100%;
    }

}

/*.checkbox-container{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    flex-wrap: nowrap;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.checkbox-container > p{*/
/*    margin-left: 10px;*/
/*    color: white;*/
/*    */
/*}*/