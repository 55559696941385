.footer{
    background-color: black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 57px
}

.footer-text{
    color: white;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
}

.footer-link{
    color: white;
    text-decoration: underline;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
}